import React, {Component, Fragment} from 'react'
import PropTypes from 'prop-types'
import {Button, Grid, Image, Divider, Label} from "semantic-ui-react";

export default class Kamer1 extends Component {


    render() {
        const {localeData, onEnlargePhoto, reserveer} = this.props

        return (
            <Fragment>
                <Grid.Row className='kamer1-color'>
                    <Grid.Column>
                        <div>{localeData.kamer1}</div>
                    </Grid.Column>

                    <Grid.Column width={6}>
                        <Image className='clickable-image' bordered rounded size='large' src='./fotos/DSC_1034.jpg' onClick={onEnlargePhoto}/>
                    </Grid.Column>

                </Grid.Row>

                <Grid.Row>

                    <Grid.Column width={4}>
                        <Image className='clickable-image' bordered rounded size='medium' src='./fotos/DSC_1035.jpg' onClick={onEnlargePhoto}/>
                    </Grid.Column>
                    <Grid.Column>
                       {localeData.kamer1_info}
                    </Grid.Column>

                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                    <Button size='tiny' onClick={reserveer}>{localeData.reserveer} La Fontaine</Button>
                </Grid.Column>
                </Grid.Row>

            </Fragment>
        )
    }
}

Kamer1.propTypes = {
    localeData: PropTypes.object.isRequired,
    onEnlargePhoto: PropTypes.func.isRequired,
    reserveer: PropTypes.func.isRequired,
}

Kamer1.defaultProps = {}