import React, {Component, Fragment} from 'react'
import PropTypes from 'prop-types'
import {Button, Grid, Image, Divider} from "semantic-ui-react";

export default class Hauteclair extends Component {

    constructor(props) {
        super(props)
        this.state = {readMore: false}
    }

    componentDidMount() {

    }

    handleReadMore = () => {
        this.setState({readMore: true})
    }

    handleClose = () => {
        this.setState({readMore: false})
    }

    render() {
        const {readMore} = this.state
        const {localeData, onEnlargePhoto} = this.props

        return (
            <Fragment>
                <Grid.Row className='grid-row' color='teal'>
                    <Grid.Column floated='right' width={6}>
                        <Image className='clickable-image' bordered rounded size='large' src='./fotos/hauteclair.jpg' onClick={onEnlargePhoto}/>
                    </Grid.Column>
                    <Grid.Column>
                        <div style={{marginBottom: '20px'}}>{localeData.intro_hauteclair}</div>

                        {!readMore &&
                        <Button size='tiny' onClick={this.handleReadMore}>{localeData.lees_meer}</Button>
                        }
                    </Grid.Column>
                </Grid.Row>

                {readMore &&
                <Fragment>

                    <Grid.Row className='grid-row'>
                        <Grid.Column>
                            <Image className='clickable-image' bordered rounded size='large' src='./fotos/DSC_1010.jpg' onClick={onEnlargePhoto}/>
                        </Grid.Column>
                        <Grid.Column>
                            <Image className='clickable-image' bordered rounded size='large' src='./fotos/IMG_0867.JPG' onClick={onEnlargePhoto}/>
                        </Grid.Column>
                        <Grid.Column>{localeData.living}</Grid.Column>
                    </Grid.Row>

                    <Grid.Row className='grid-row'>
                        <Grid.Column>
                            <Image className='clickable-image' bordered rounded size='large' src='./fotos/DSC_1016.jpg' onClick={onEnlargePhoto}/>
                        </Grid.Column>
                        <Grid.Column>{localeData.eetkamer}</Grid.Column>
                        <Grid.Column width={4}>
                            <Image className='clickable-image' bordered rounded size='large' src='./fotos/IMG_0871.JPG' onClick={onEnlargePhoto}/>
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row className='grid-row'>
                        <Grid.Column>{localeData.keuken}</Grid.Column>
                        <Grid.Column>
                            <Image className='clickable-image' bordered rounded size='large' src='./fotos/DSC_1005.jpg' onClick={onEnlargePhoto}/>
                        </Grid.Column>
                        <Grid.Column>
                            <Image className='clickable-image' bordered rounded size='large' src='./fotos/DSC_1007.jpg' onClick={onEnlargePhoto}/>
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row className='grid-row'>
                        <Grid.Column>
                            <Image className='clickable-image' bordered rounded size='large' src='./fotos/DSC_1008.jpg' onClick={onEnlargePhoto}/>
                        </Grid.Column>
                        <Grid.Column>{localeData.terras}</Grid.Column>
                        <Grid.Column>
                            <Image className='clickable-image' bordered rounded size='large' src='./fotos/DSC_1009.jpg' onClick={onEnlargePhoto}/>
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                        <Grid.Column><Button size='tiny' onClick={this.handleClose}>{localeData.sluit}</Button></Grid.Column>
                    </Grid.Row>
                </Fragment>
                }

            </Fragment>
        )
    }
}

Hauteclair.propTypes = {
    localeData: PropTypes.object.isRequired,
    onEnlargePhoto: PropTypes.func.isRequired,
}

Hauteclair.defaultProps = {}