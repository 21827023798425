import React, {Component} from 'react'
import {Image, Grid, Button, Modal, Divider, Menu} from "semantic-ui-react"

import Intro from './Intro'
import '../index.css'
import locale_nl from '../assets/locale_nl'
import locale_fr from '../assets/locale_fr'
import Hauteclair from "./Hauteclair";
import Kamers from "./Kamers";
import Activiteiten from "./Activiteiten";
import Praktisch from "./Praktisch";
import GoogleMap from "./GoogleMap";
import Reserveren from "./Reserveren";

const GOOGLE_MAP_LINK = "https://www.google.nl/maps/place/Résidence+Hauteclair/@45.2487631,2.6934015,17.52z/data=!4m16!1m7!3m6!1s0x47f7ecc2a6633217:0xf37c42cb87cafbb0!2s15400+Apchon!3b1!8m2!3d45.246602!4d2.692952!3m7!1s0x47f7ece7ff6230b9:0x17e011699b04a496!5m2!4m1!1i2!8m2!3d45.2489191!4d2.6945908"

export default class Main extends Component {

    constructor(props) {
        super(props)
        this.state = {
            locale: 'nl',
            localeData: locale_nl,
            activeMenuItem: 'hauteclair',
            fotoDetailOpen: false, fotoDetailSource: ''
        }
    }

    componentDidMount() {
        console.log('LOC', locale_nl)
    }

    switchLanguage = (event, {name}) => {
        const localeData = name === 'nl' ? locale_nl : locale_fr
        this.setState({locale: name, localeData})
    }

    enlargePhoto = (event) => {
        this.setState({fotoDetailOpen: true, fotoDetailSource: event.target.getAttribute("src")})
    }

    sluitFotoDetail = (event) => {
        this.setState({fotoDetailOpen: false})
    }

    handleMenuClick = (event, {name}) => {
        this.setState({activeMenuItem: name})
    }

    onMapClick = (event) => {
        console.log('KST')
        window.open(GOOGLE_MAP_LINK, '_blank');
    }

    onReserveer = () => {
        this.setState({activeMenuItem: 'reserveren'})
    }

    render() {
        const {locale, localeData, fotoDetailOpen, fotoDetailSource, activeMenuItem} = this.state
        return (
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>

                <div className='banner'>
                    <Image src='./fotos/homeBanner.jpg'/>
                    {/*<div style={{position: 'absolute', left: '20px', top: '20px'}}>
                        <GoogleMap center={{lat: 45.2480235, lng: 2.693051}} zoom={11} onMarkerClick={this.onMapClick}/>
                    </div>*/}

                    <div style={{
                        position: 'absolute', bottom: '10%', right: 50,
                        color: 'white', fontSize: '6vw', textShadow: '2px 2px #000',
                        lineHeight: 0.8
                    }}>{localeData.welkom_in_hauteclair}</div>
                    {/*<div style={{position: 'absolute', top: 50, right: 70, color: 'yellow'}}>Heerlijk wegdromen in de Cantal</div>
                    <div style={{position: 'absolute', bottom: 20, right: 70, color: 'yellow'}}>Heerlijk wegdromen in de Cantal</div>*/}
                    <div style={{position: 'absolute', top: 20, right: 50}}>
                        <Button.Group size='tiny'>
                            <Button toggle active={locale === 'nl'} name='nl' onClick={this.switchLanguage} content='nl'/>
                            <Button toggle active={locale === 'fr'} name='fr' onClick={this.switchLanguage} content='fr'/>
                        </Button.Group>
                    </div>
                </div>

                <Menu pointing secondary>
                    <Menu.Item name='hauteclair' active={activeMenuItem === 'hauteclair'} onClick={this.handleMenuClick}/>
                    <Menu.Item name='activiteiten' active={activeMenuItem === 'activiteiten'} onClick={this.handleMenuClick}>
                        {localeData.menu.activiteiten}
                    </Menu.Item>
                    <Menu.Item name='praktisch' active={activeMenuItem === 'praktisch'} onClick={this.handleMenuClick}>
                        {localeData.menu.praktisch}
                    </Menu.Item>
                    <Menu.Item name='reserveren' active={activeMenuItem === 'reserveren'} onClick={this.handleMenuClick}>
                        {localeData.menu.reserveren}
                    </Menu.Item>
                </Menu>

                {activeMenuItem === 'hauteclair' &&
                <Grid style={{maxWidth: '1080px'}} padded verticalAlign='middle' stackable columns='equal'>


                    <Intro localeData={localeData} onEnlargePhoto={this.enlargePhoto}/>
                    <Divider/>
                    <Hauteclair localeData={localeData} onEnlargePhoto={this.enlargePhoto}/>
                    <Divider/>
                    <Kamers localeData={localeData} onEnlargePhoto={this.enlargePhoto} onReserveer={this.onReserveer}/>

                </Grid>
                }

                {activeMenuItem === 'activiteiten' &&
                <Grid style={{maxWidth: '1080px'}} padded verticalAlign='middle' stackable columns='equal'>
                    <Activiteiten localeData={localeData} onEnlargePhoto={this.enlargePhoto}/>

                </Grid>
                }

                {activeMenuItem === 'praktisch' &&
                <Grid style={{maxWidth: '1080px'}} padded verticalAlign='middle' stackable columns='equal'>
                    <Praktisch localeData={localeData} onEnlargePhoto={this.enlargePhoto}/>
                </Grid>
                }

                {activeMenuItem === 'reserveren' &&
                <Grid style={{maxWidth: '1080px'}} padded verticalAlign='middle' stackable columns='equal'>
                    <Reserveren localeData={localeData} onEnlargePhoto={this.enlargePhoto}/>
                </Grid>
                }

                <p style={{fontSize: '0.5em'}}>©2019 Hauteclair</p>

                <Modal
                    open={fotoDetailOpen}
                    onClose={this.sluitFotoDetail}
                    basic
                    size='large'
                >
                    <Modal.Content>
                        <Image src={fotoDetailSource} fluid/>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color='green' onClick={this.sluitFotoDetail} inverted>{localeData.sluit}
                        </Button>
                    </Modal.Actions>
                </Modal>
            </div>
        )
    }
}

Main.propTypes = {}

Main.defaultProps = {}
