import React from 'react'
import PropTypes from 'prop-types'
import GoogleMapReact from 'google-map-react'
import {Icon} from "semantic-ui-react";

const API_KEY = 'AIzaSyA6a1NnXPaROQ7eY8QPIYbgO9yDGgSO0sA'

const GoogleMap = (props) => {

    const handleApiLoaded = (map, maps) => {
        // use map and maps objects
        console.log('MAP', map, maps)
        map.fullscreenControl = false
        map.zoomControl = false
    };

    return (
        <div style={{ height: '200px', width: '300px' }}>
            <GoogleMapReact
                bootstrapURLKeys={{ key: API_KEY }}
                defaultCenter={props.center}
                defaultZoom={props.zoom}
                yesIWantToUseGoogleMapApiInternals={true}
                onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
            >

                <Icon
                    style={{position: 'absolute', transform: 'translate(-50%, -100%)', cursor: 'pointer'}}
                    name='map marker alternate' size='big' onClick={props.onMarkerClick}
                    lat={45.2480235}
                    lng={2.693051}
                    text="Hauteclair"
                />
            </GoogleMapReact>
        </div>
    )
}

export default  GoogleMap

GoogleMap.propTypes = {
    center: PropTypes.object,
    zoom: PropTypes.number,
    onMarkerClick: PropTypes.func
}

GoogleMap.defaultProps = {

}