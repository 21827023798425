import React, {Component, Fragment} from 'react'
import PropTypes from 'prop-types'
import {Button, Grid, Image, Transition} from "semantic-ui-react";

export default class Intro extends Component {

    constructor(props) {
        super(props)
        this.state = {readMore: false}
    }

    componentDidMount() {

    }

    handleReadMore = () => {
        this.setState({readMore: true})
    }

    render() {
        const {readMore} = this.state
        const {localeData, onEnlargePhoto} = this.props

        return (
            <Fragment>
                <Grid.Row>
                    <Grid.Column>
                        <div style={{marginBottom: '20px'}}>{localeData.intro_omgeving}</div>

                        {!readMore &&
                        <Button size='tiny' onClick={this.handleReadMore}>{localeData.lees_meer}</Button>
                        }
                    </Grid.Column>
                    <Grid.Column floated='right' width={6}>
                        <Image className='clickable-image' bordered rounded size='large' src='./fotos/IMG_1952.jpg' onClick={onEnlargePhoto}/>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row className='grid-row'>

                    <Transition.Group animation='drop' duration={5000}>
                        <Grid.Column>
                            {readMore &&
                            <Fragment>
                                {localeData.omgeving_lees_meer}
                            </Fragment>
                            }
                        </Grid.Column>
                    </Transition.Group>
                </Grid.Row>
            </Fragment>
        )
    }
}

Intro.propTypes = {
    localeData: PropTypes.object.isRequired,
    onEnlargePhoto: PropTypes.func.isRequired,
}

Intro.defaultProps = {}