import React from 'react'
import {Header, Label} from 'semantic-ui-react'

export default {

    menu: {activiteiten: 'Activités', praktisch: 'Pratique', reserveren: 'Réservation'},

    eetkamer: <div>Notre spacieuse <h2> salle à manger </ h2> peut accueillir de 10 à 12 personnes et peut savourer notre délicieux petit-déjeuner ou un dîner préparé par nos soins l'après-midi ou le dîner. WiFi gratuit et la télévision sont disponibles.</div>,



    reserveren_hauteclair: <div style={{ fontSize: '1.2em' }}>
        <Header as='h3' style={{ fontSize: '2em' }}>
            Hauteclair
        </Header>
        <p>Ady Pinckers</p>
        <p>Résidence Hauteclair</p>
        <p>Le Bourg<br/>
            15400 Apchon<br/>
            fixe: 04 71 78 20 07<br/>
            mobile: 06 31 30 83 33<br/>
            couriel: <a href='mailto:adypinckers@hotmail.com' target='_blank' rel="noopener noreferrer">adypinckers@hotmail.com</a>
        </p>
    </div>,

    reserveren_bijkomend: <div>
        <Header as='h3' style={{ fontSize: '2em' }}>
            Informations complémentaires sur les sites suivants
        </Header>
        <p><a href='https://www.tourisme.fr/518/office-de-tourisme-riom-es-montagnes.htm' target='_blank' rel="noopener noreferrer">OT Pays Gentiane</a></p>
        <p><a href='http://www.cantal-destination.com' target='_blank' rel="noopener noreferrer">Cantaltourisme</a></p>
        <p><a href='http://www.auvergne-tourisme.info/accueil-1-1.html' target='_blank' rel="noopener noreferrer">Auvergnetourisme</a></p>
        <p><a href='https://www.airbnb.fr' target='_blank' rel="noopener noreferrer">Airbnb</a></p>
        <p><a href='https://www.geo.fr' target='_blank' rel="noopener noreferrer">Geo Guide</a></p>
        <p><a href='https://www.petitfute.com' target='_blank' rel="noopener noreferrer">Petit Futé</a></p>
    </div>,

    praktisch_restaurants: <div>
        <Header as='h3' style={{ fontSize: '2em' }}>
            Restaurants à proximité
        </Header>
        <p style={{ fontSize: '1.2em' }}>
            Dans le village d'Apchon à 100m et 5à10 km en voiture à Riom-ès-Montagnes, Cheylade, Trizac et Valette
        </p>
    </div>,
    praktisch_bakker: <div>
        <Header as='h3' style={{ fontSize: '2em' }}>
            Boulanger et épicier
        </Header>
        <p style={{ fontSize: '1.2em' }}>
            Dans le village d'Apchon.
        </p>
    </div>,
    praktisch_supermarkt: <div>
        <Header as='h3' style={{ fontSize: '2em' }}>
            Supermarchés et bouchers
        </Header>
        <p style={{ fontSize: '1.2em' }}>
            A Riom-es-Montagnes
        </p>
    </div>,

    intro_activiteiten: <div>
        <strong as='h1'>
            Quelque chose pour tout le monde
        </strong>
        <Header as='h3' style={{ fontSize: '2em' }}>
            Nombreuses activités tout au long de l'année
        </Header>
        <p style={{ fontSize: '1.2em' }}>
            En plus d'innombrables belles promenades, le Cantal propose un large éventail d'activités très diverses. Si vous êtes sportif, vous pouvez faire du VTT, de l'équitation. Pour les plus téméraires: c’est un paradis pour le parapente ici. <br/>
            Si vous préférez le garder un peu plus silencieux, vous pouvez réserver une excursion à dos d’âne ou une excursion en bateau sur l’un des lacs de la région. Ou pourquoi ne pas aller pêcher pendant une journée?
        </p>
    </div>,

    activiteiten_vulcania: <div>
        <Header as='h3' style={{ fontSize: '2em' }}>
            Vulcania
        </Header>
        <p style={{ fontSize: '1.2em' }}>
            Un nouveau centre magnifique sur les volcans d'Auvergne. Une excursion d'une journée est un must! Assurez-vous de fournir une journée complète car le site est très grand. Si vous voulez tout voir, vous devez quand même prendre 8 heures de congé. Demandez une brochure dans les offices de tourisme.
        </p>
        <p>Plus d'infos sur <a href='https://www.vulcania.com' target='_blank' rel="noopener noreferrer">le site de vulcania</a></p>
    </div>,
    activiteiten_maison_aveze: <div>
        <Header as='h3' style={{ fontSize: '2em' }}>
            Maison d'Avèze
        </Header>
        <p style={{ fontSize: '1.2em' }}>
            Plus qu’une boisson, Avèze correspond à une activité artisanale fortement ancrée dans la tradition auvergnate. Cela méritait bien un musée !
        </p>
        <p>Plus d'infos sur <a href='https://www.aveze.com/lespace-aveze/' target='_blank' rel="noopener noreferrer">le site de la maison d'avèze</a></p>
    </div>,
    activiteiten_maison_du_miel: <div>
        <Header as='h3' style={{ fontSize: '2em' }}>
            Le monde des abeilles
        </Header>
        <p style={{ fontSize: '1.2em' }}>
            Ferme en pleine nature au cœur du Parc des Volcans. Vente de produits de la Ruche, vidéo explicative du métier d'apiculteur. Exposition : évolution des ruches, produits récoltés, ruche vitrée avec des abeilles en activité, miellerie avec ses appareils. Espace de vente, dégustation.
        </p>
        <p>Plus d'infos sur <a href='http://auvergne-centrefrance.com/saveurs/miel/stsaturnin15/miellerie-le-monde-des-abeilles.html' target='_blank' rel="noopener noreferrer">Miellerie Le Monde des Abeilles</a></p>
    </div>,
    activiteiten_gentiane_express: <div>
        <Header as='h3' style={{ fontSize: '2em' }}>
            Gentiane express
        </Header>
        <p style={{ fontSize: '1.2em' }}>
            Montez à bord du Gentiane Express® pour un voyage inoubliable au coeur du Pays Gentiane !
        </p>
        <p>Plus d'infos sur <a href='https://www.gentiane-express.com' target='_blank' rel="noopener noreferrer">le site du Gentiane Express</a></p>
    </div>,
    activiteiten_fromages: <div>
        <Header as='h3' style={{ fontSize: '2em' }}>
            Route des Fromages
        </Header>
        <p style={{ fontSize: '1.2em' }}>
            Au gré des 38 étapes de la Route des Fromages (producteurs fermiers, fromageries-laiteries, affineurs), vous découvrirez toute la diversité des terroirs, des saveurs et des savoir-faire des 5 fromages AOP d’Auvergne : Bleu d’Auvergne, Cantal, Fourme d’Ambert, Saint-Nectaire, Salers.
        </p>
        <p>Plus d'infos sur <a href='https://www.fromages-aop-auvergne.com/route-fromages/' target='_blank' rel="noopener noreferrer">le site du 'Route Des Fromages AOP d'Auvergne'</a></p>
    </div>,
    activiteiten_chateau_apchon: <div>
        <Header as='h3' style={{ fontSize: '2em' }}>
            Château d'Apchon
        </Header>
        <p style={{ fontSize: '1.2em' }}>
            Les ruines du château d’Apchon constituent un site exceptionnel du nord Cantal. Elles sont situées à plus de 1100 m d’altitude sur un impressionnant neck basaltique, d’où elles dominent la vallée de la Petite-Rhue et le Pays Gentiane.
        </p>
        <p>Plus d'infos sur <a href='https://www.fondation-patrimoine.org/les-projets/chateau-d-apchon' target='_blank' rel="noopener noreferrer">ce site</a></p>
    </div>,
    activiteiten_chaudes_aigues: <div>
        <Header as='h3' style={{ fontSize: '2em' }}>
            Chaudes-Aigues
        </Header>
        <p style={{ fontSize: '1.2em' }}>
            Lovée entre Monts du Cantal et le plateau de l’Aubrac, à deux pas de Saint-Flour et des gorges de la Truyère, Chaudes-Aigues, unique station thermale du Cantal, est une destination prisée pour toute la famille. Cette cité thermale à l’architecture en pierre et toits de lauze est appréciée pour sa dimension humaine et son cadre naturel exceptionnel.
        </p>
        <p>Plus d'infos sur <a href='https://www.pays-saint-flour.fr/incontournables/chaudes-aigues-station-thermale/' target='_blank' rel="noopener noreferrer">ce site</a></p>
    </div>,
    activiteiten_ski: <div>
        <Header as='h3' style={{ fontSize: '2em' }}>
            En hiver ski et ski de fond
        </Header>
        <p style={{ fontSize: '1.2em' }}>
            Hauteclair est situé au milieu de 2 stations de ski: Le Lioran au sud et Super-Besse au nord, à 30-30 minutes
        </p>
        <p>Plus d'infos: <a href='https://www.lelioran.com/index.php?alias=home&chg_saison=hiver' target='_blank' rel="noopener noreferrer">Le Lioran</a> et <a href='https://www.sancy.com/domaine-vtt/station-de-super-besse/' target='_blank' rel="noopener noreferrer">Super Besse</a>
        </p>
    </div>,
    activiteiten_parcours_aventure: <div>
        <Header as='h3' style={{ fontSize: '2em' }}>
            Parcours aventure du Lioran
        </Header>
        <p style={{ fontSize: '1.2em' }}>
            A 30 min, d'Apchon, dans la jungle verte du Lioran, 9 parcours d'arbre en arbre apportant émotion et sensation, accessible à tous à partir de 3 ans.
        </p>
        <p>Plus d'infos sur <a href='http://auvergne.travel/activites-sportives-culturelles-sejour-itinerants/laveissiere/lioran-aventure-parcours-aventure-en-foret/tourisme-ASCAUV0150000285-1.html' target='_blank' rel="noopener noreferrer">ce site</a></p>
    </div>,
    activiteiten_velorail: <div>
        <Header as='h3' style={{ fontSize: '2em' }}>
            Vélorail Allanche
        </Header>
        <p style={{ fontSize: '1.2em' }}>
            Amateurs de nature, de panoramas, éco-touristes sensibles à la protection de l'environnement, parcourez les circuits du Vélorail du Cézallier, sur les rails de la transhumance.
        </p>
        <p>Plus d'infos sur <a href='http://www.allanche.fr/velorail.php' target='_blank' rel="noopener noreferrer">ce site</a></p>
    </div>,
    activiteiten_pinatelle: <div>
        <Header as='h3' style={{ fontSize: '2em' }}>
            Maison de la Pinatelle
        </Header>
        <p style={{ fontSize: '1.2em' }}>
            Alors que résonne le brame du cerf aux premières lueurs d'automne, la Pinhatéla vous ouvre ses portes, vous livre ses secrets.
        </p>
        <p>Plus d'infos sur <a href='http://www.auvergne-centrefrance.com/geotouring/curieux/chalinargues/chalinargues.html' target='_blank' rel="noopener noreferrer">le site de la maison de la Pinatelle</a></p>
    </div>,
    activiteiten_garabit: <div>
        <Header as='h3' style={{ fontSize: '2em' }}>
            Viaduc de Garabit
        </Header>
        <p style={{ fontSize: '1.2em' }}>
            Plus de 130 ans après sa construction, le viaduc de Garabit suscite l’admiration de tous. A ses pieds, la Truyère a creusé son lit dans une somptueuse vallée aux multiples facettes
        </p>
        <p>Plus d'infos sur <a href='https://www.pays-saint-flour.fr/incontournables/viaduc-de-garabit/' target='_blank' rel="noopener noreferrer">ce site</a></p>
    </div>,
    activiteiten_millau: <div>
        <Header as='h3' style={{ fontSize: '2em' }}>
            Viaduc de Millau
        </Header>
        <p style={{ fontSize: '1.2em' }}>
            Il a beau être un viaduc contemporain, une véritable prouesse architecturale, son allure légère et
            élancée fait qu’il s’intègre à merveille dans le paysage.
            Vos vacances en Aveyron ne seront pas complètes sans une visite de près ou de loin à ce géant d’acier.
        </p>
        <p>Plus d'infos sur <a href='https://www.tourisme-aveyron.com/nl/ontdekken/viaduct-van-millau/ontdek-viaduct-van-millau' target='_blank' rel="noopener noreferrer">ce site</a></p>
    </div>,
    activiteiten_font_sainte: <div>
        <Header as='h3' style={{ fontSize: '2em' }}>
            La Font Sainte
        </Header>
        <p style={{ fontSize: '1.2em' }}>
            A 1230 m au milieu des pâturages, s'élève une chapelle à côté d'une source sacrée. On vénère dans ce sanctuaire une Vierge offerte au XVIIIème s. par un évêque de Clermont Ferrand à une bergère qui aurait eu une apparition près de cette source. Dans ce lieu très paisible, vous pouvez emprunter le chemin qui mène à la colline, d'où vous admirerez le panorama sur le Plateau du Limon. Accès depuis Apchon ou Saint- Hippolyte. Balade de sculpture parlante en pierre de Menet
        </p>
        <p>Plus d'infos sur <a href='http://www.cantal-destination.com/patrimoine-culturel/saint-hippolyte/la-font-sainte/tourisme-PCUAUV0150000402-1.html' target='_blank' rel="noopener noreferrer">ce site</a></p>
    </div>,
    activiteiten_marcenat: <div>
        <Header as='h3' style={{ fontSize: '2em' }}>
            Monastère orthodoxe de Marcenat
        </Header>
        <p style={{ fontSize: '1.2em' }}>
            Un monastère orthodoxe unique dans un endroit où on s'y attendrait le moins.
            Il s’agit d’une ancienne grange transformée, un lieu unique en France dans le Cézallier cantalien : La traverse. Cette dépendance du patriarcat de Moscou est située sur la commune de Marcenat, reconnaissable à sa coupole de cuivre. Aménagé et restructuré par les moniales (1) et des bénévoles, 6 ans de travaux ont été nécessaires pour remettre en état et transformer cette ancien corps de ferme.
        </p>
        <p>Plus d'infos sur <a href='http://www.auvergne-centrefrance.com/geotouring/eglises/15/marcenat/monastere-orthodoxe-de-la-traverse.html' target='_blank' rel="noopener noreferrer">ce site</a></p>
    </div>,
    activiteiten_markten: <div>
        <Header as='h3' style={{ fontSize: '2em' }}>
            Fêtes et marchés
            Fêtes et marchés
        </Header>
        <p style={{ fontSize: '1.2em' }}>
            Fête de la Gentiane Riom-ès-Montagnes<br/>
            Fête de l’Estive Allanche<br/>
            Fête de la Rosière Riom-ès-Montagnes<br/>
            Fête de la pierre de Menet<br/>
            Festival des Nuits Musicales<br/>
            Fête du Bleu Riom-ès-Montagnes<br/>
            Fête du Cornet de Murat<br/>
            Brocante et vide grenier<br/>
            Foire aux Cloche et Sonnailles Cheylade<br/>
            Foire à la brocante et aux antiquités Allanche<br/>

        </p>
    </div>,

    intro_omgeving: <div>
        <strong as='h1'>
            Cantal - Auvergne
        </strong>
        <Header as='h3' style={{ fontSize: '2em' }}>
            Laissez-vous séduire par la nature
        </Header>
        <p style={{ fontSize: '1.33em' }}>
            Des vacances en plein centre du plus grand site volcanique d’Europe. Vous trouverez des paysages grandioses, des panoramas immenses, des lacs, des rivières. Chaque bourg a son cachet, sa personnalité et ses richesses.  Auvergne – Massif du Cantal – Parc Régional des Volcans – un des parcs naturels où l’air et l’eau pure n’est pas une utopie.
        </p>
    </div>,

    omgeving_lees_meer: <div>
        <p style={{ fontSize: '1.2em' }}>
            Oublier le stress des villes et venez vous remettre en pleine forme dans le pays des sources innombrables dans le sud du Cantal près de Riom-es-Montagnes, à quelques km du plus majestueux volcan d’Europe le Puy Mary (1787m) et du Plomb du Cantal (1900m), 50km de la ville culturel d’Aurillac et 80km de Clermont-Ferrand.A l’ombre des ruines du château d’Apchon, ce bourg médiéval se trouve sur un dyke basaltique à 1050m de haut.  De là on découvre des points de vue extraordinaires sur les vallées environantes.
        </p>
    </div>,

    intro_hauteclair: <div>
        <strong as='h1'>
            Hauteclair
        </strong>
        <Header as='h3' style={{ fontSize: '2em' }}>
            Retrouvez vos sources dans notre belle résidence
        </Header>
        <p style={{ fontSize: '1.2em' }}>
            Cette demeure a été rénovée par les propriétaires, tout en gardant son authenticité. Le mobilier, les peintures et la décoration sont de la création des propriétaires. La résidence vous offre une terrasse suspendue et des jardins clos avec vue panoramique allant du Puy-Mary au Puy de Sancy en passant par les plateaux du Limon et le Peyre-Arse.
        </p>
    </div>,

    hauteclair_lees_meer: <div>
        <p style={{ fontSize: '1.2em' }}>
            Emplacement motos, vélos et matériel de pêche.
        </p>
    </div>,

    intro_kamers: <div>
        <Header as='h3' style={{ fontSize: '2em' }}>
            Cinq chambres pour tous les goûts
        </Header>
        {/*<p style={{ fontSize: '1.2em' }}>
            In het hartje van het Parc Régional des Volcans d’Auvergne, 'le grand bol d'air' van Frankrijk vind je onze unieke chambres d'hôtes. Je wordt ondergedompeld tussen diep ingedommelde vulkanen, rustgevende bossen en magische landschappen met grandioze panorama's
        </p>*/}
    </div>,

    kamer1: <div>
        <strong as='h1'>
            Chambre 1
        </strong>
        <Header as='h3' style={{ fontSize: '2em' }}>
            La Fontaine
        </Header>
        <p style={{ fontSize: '1.2em' }}>
            La vue de la chambre est sur la Font Sainte lieu de pèlerinage et avec une source férigineuse
            <br/>Une chambre élégante avec sa propre salle de bain
        </p>
    </div>,

    kamer1_info: <div>
        <div style={{ fontSize: '1.2em' }}>
            <p> Prix pour cette chambre:
                <br /> 69 € pour 2 personnes par nuit.
                <br /> 69 € pour 1 personne par nuit. </ p>
            <p> Petit-déjeuner inclus. </ p>
            <p> Taxe de séjour non incluse: 0,75 € + t.s. par personne. </ p>
            <Label tag color = 'teal'> Réservation de 7 nuits = 1 nuit supplémentaire offerte </Label>
        </div>
    </div>,

    kamer2: <div>
        <strong as='h1'>
            Chambre 2
        </strong>
        <Header as='h3' style={{ fontSize: '2em' }}>
            Le Cheylet
        </Header>
        <p style={{ fontSize: '1.2em' }}>
            La vue est sur le jardin et en hauteur sur le cheylet, la Vierge Marie qui nous protège contre la foudre.
            <br/>Une chambre familiale très spacieuse et élégante avec salle de bain séparée
        </p>
    </div>,

    kamer2_info: <div>
        <div style={{ fontSize: '1.2em' }}>
            <p> Prix pour cette chambre:
                <br /> 79 € pour 2 personnes par nuit.
                <br /> 1 lit d'enfant supplémentaire gratuit (jusqu'à 2 ans).
                <br /> 25 € pour 1 lit d'enfant supplémentaire (à partir de 2 ans).
            </ p>
            <p> Petit-déjeuner inclus. </ p>
            <p> Taxe de séjour non incluse: 0,75 € t.s. par personne. </ p>
            <Label tag color = 'teal'> Réservez à partir de 4 nuits: 69 € par nuit! </ Label>
        </div>
    </div>,


    kamer3: <div>
        <strong as='h1'>
            Chambre 3
        </strong>
        <Header as='h3' style={{ fontSize: '2em' }}>
            Le Château
        </Header>
        <p style={{ fontSize: '1.2em' }}>
            La vue est sur la dyke où se trouve le château d'Apchon.
            <br/>Une chambre spacieuse et élégante avec sa propre salle de bain
        </p>
    </div>,

    kamer3_info: <div>
        <div style={{ fontSize: '1.2em' }}>
            <p> Prix pour cette chambre:
                <br /> 69 € pour 2 personnes par nuit.
                <br /> 69 € pour 1 personne par nuit.
                <br /> 25 € pour 1 lit d'enfant supplémentaire (à partir de 2 ans).
            </ p>
            <p> Petit-déjeuner inclus. </ p>
            <p> Taxe de séjour non incluse: 0,75 € + t.s. par personne. </ p>
            <Label tag color = 'teal'> Réservation de 7 nuits = 1 nuit supplémentaire offerte </ Label>
        </div>
    </div>,

    kamer4: <div>
        <strong as='h1'>
            Chambre 4
        </strong>
        <Header as='h3' style={{ fontSize: '2em' }}>
            Le Plateau
        </Header>
        <p style={{ fontSize: '1.2em' }}>
            La vue est sur le plateau du Limon et la vallée.
            <br/>Chambre élégante avec 2 lits simples et salle de bain privée
        </p>
    </div>,

    kamer4_info: <div>
        <div style={{ fontSize: '1.2em' }}>
            <p> Prix pour cette chambre:
                <br /> 69 € pour 2 personnes par nuit.
                <br /> 69 € pour 1 personne par nuit.
            </ p>
            <p> Petit-déjeuner inclus. </ p>
            <p> Taxe de séjour non incluse: 0,75 € + t.s. par personne. </ p>
            <Label tag color = 'teal'> Réservation de 7 nuits = 1 nuit supplémentaire offerte </ Label>
        </div>
    </div>,

    kamer5: <div>
        <strong as='h1'>
            Chambre 5
        </strong>
        <Header as='h3' style={{ fontSize: '2em' }}>
            Le Peyre Arse
        </Header>
        <p style={{ fontSize: '1.2em' }}>
            La vue sur les jardins et le mont du Peyre Arse.
            <br/>Une chambre familiale très spacieuse et élégante avec un lit double et un lit simple et des installations sanitaires séparées
        </p>
    </div>,

    kamer5_info: <div>
        <div style={{ fontSize: '1.2em' }}>
            <p> Prix pour cette chambre:
                <br /> 79 € pour 2 personnes par nuit.
                <br /> 25 € pour 1 lit d'enfant supplémentaire (à partir de 2 ans).
            </ p>
            <p> Petit-déjeuner inclus. </ p>
            <p> Taxe de séjour non incluse: 0,75 € + t.s. par personne. </ p>
            <Label tag color = 'teal'> Réservez à partir de 4 nuits: 69 € par nuit! </ Label>
        </div>
    </div>,

    keuken: <div> Nous fournissons une <h2> cuisine </ h2> bien équipée aux clients souhaitant préparer leur pique-nique ou leur repas l'après-midi ou le soir. </ div>,

    lees_meer: 'Lire plus',

    living: <div> Dans notre spacieux <h2> Salon </ h2>, vous pouvez vous installer confortablement et regarder la télévision, mais vous pouvez également savourer un délicieux petit-déjeuner copieux. </ div>,

    reserveer: 'Reserver',

    sluit: 'Fermer',

    terras: <div> Sur notre grande <h2> terrasse </ h2>, vous pourrez, si le temps le permet, profiter d’une vue phénoménale sur la vallée de la Cheylade. La terrasse est orientée au sud pour que vous puissiez profiter du soleil toute la journée. </div>,

    welkom_in_hauteclair: 'Bienvenu à Hauteclair',
}
