import React, {Component, Fragment} from 'react'
import PropTypes from 'prop-types'
import {Button, Divider, Grid, Icon, Image} from "semantic-ui-react";
import GoogleMapReact from "google-map-react";
const API_KEY = 'AIzaSyA6a1NnXPaROQ7eY8QPIYbgO9yDGgSO0sA'

export default class Reserveren extends Component {

    constructor(props) {
        super(props)
        this.state = {readMore: false}
    }

    componentDidMount() {

    }

    handleReadMore = () => {
        this.setState({readMore: true})
    }

    render() {
        const {readMore} = this.state
        const {localeData, onEnlargePhoto} = this.props

        return (
            <Fragment>


                {/*HAUTECLAIR*/}
                <Grid.Row className='grid-row'>
                    <Grid.Column>
                        <div style={{marginBottom: '20px'}}>{localeData.reserveren_hauteclair}</div>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row className='grid-row'>
                    <Grid.Column>

                        <div style={{ height: '300px', width: '100%' }}>
                            <GoogleMapReact
                                bootstrapURLKeys={{ key: API_KEY }}
                                defaultCenter={{lat: 45.2486771, lng: 2.6939491}}
                                defaultZoom={14}
                                yesIWantToUseGoogleMapApiInternals={true}
                            >
                                <Icon
                                    style={{position: 'absolute', transform: 'translate(-50%, -100%)', cursor: 'pointer'}}
                                    name='map marker alternate' size='big'
                                    lat={45.2486771}
                                    lng={2.6939491}
                                    text="Riom-es-Montagnes"
                                />
                            </GoogleMapReact>
                        </div>
                    </Grid.Column>
                </Grid.Row>

                <Divider/>

                {/*BIJKOMEND*/}
                <Grid.Row className='grid-row'>
                    <Grid.Column>
                        <div style={{marginBottom: '20px'}}>{localeData.reserveren_bijkomend}</div>
                    </Grid.Column>
                </Grid.Row>
            </Fragment>
        )
    }
}

Reserveren.propTypes = {
    localeData: PropTypes.object.isRequired,
    onEnlargePhoto: PropTypes.func.isRequired,
}

Reserveren.defaultProps = {}