import React from 'react'
import Main from './component/Main'

function App() {
    return (
    <Main></Main>
    )
}

export default App;
