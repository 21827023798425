import React, {Component, Fragment} from 'react'
import PropTypes from 'prop-types'
import {Button, Divider, Grid, Icon, Image} from "semantic-ui-react";
import GoogleMapReact from "google-map-react";

const GOOGLE_MAP_LINK = "https://www.google.nl/maps/place/Résidence+Hauteclair/@45.2487631,2.6934015,17.52z/data=!4m16!1m7!3m6!1s0x47f7ecc2a6633217:0xf37c42cb87cafbb0!2s15400+Apchon!3b1!8m2!3d45.246602!4d2.692952!3m7!1s0x47f7ece7ff6230b9:0x17e011699b04a496!5m2!4m1!1i2!8m2!3d45.2489191!4d2.6945908"

const API_KEY = 'AIzaSyA6a1NnXPaROQ7eY8QPIYbgO9yDGgSO0sA'

export default class Praktisch extends Component {

    constructor(props) {
        super(props)
        this.state = {readMore: false}
    }

    componentDidMount() {

    }

    handleReadMore = () => {
        this.setState({readMore: true})
    }

    render() {
        const {readMore} = this.state
        const {localeData, onEnlargePhoto} = this.props

        return (
            <Fragment>


                {/*RESTAURANT*/}
                <Grid.Row className='grid-row'>
                    <Grid.Column>
                        <div style={{marginBottom: '20px'}}>{localeData.praktisch_restaurants}</div>
                    </Grid.Column>
                    <Grid.Column floated='right' width={8}>
                        <div style={{ height: '300px', width: '100%' }}>
                            <GoogleMapReact
                                bootstrapURLKeys={{ key: API_KEY }}
                                defaultCenter={{lat: 45.2480235, lng: 2.693051}}
                                defaultZoom={11}
                                yesIWantToUseGoogleMapApiInternals={true}
                            >
                                <Icon
                                    style={{position: 'absolute', transform: 'translate(-50%, -100%)', cursor: 'pointer'}}
                                    name='map marker alternate' size='big'
                                    lat={45.2480235}
                                    lng={2.693051}
                                    text="Apchon"
                                />
                                <Icon
                                    style={{position: 'absolute', transform: 'translate(-50%, -100%)', cursor: 'pointer'}}
                                    name='map marker alternate' size='big'
                                    lat={45.281418}
                                    lng={2.659765}
                                    text="Riom-es-Montagnes"
                                />
                                <Icon
                                    style={{position: 'absolute', transform: 'translate(-50%, -100%)', cursor: 'pointer'}}
                                    name='map marker alternate' size='big'
                                    lat={45.208276}
                                    lng={2.716221}
                                    text="Cheylade"
                                />
                                <Icon
                                    style={{position: 'absolute', transform: 'translate(-50%, -100%)', cursor: 'pointer'}}
                                    name='map marker alternate' size='big'
                                    lat={45.2514268}
                                    lng={2.53605}
                                    text="Trizac"
                                />
                                <Icon
                                    style={{position: 'absolute', transform: 'translate(-50%, -100%)', cursor: 'pointer'}}
                                    name='map marker alternate' size='big'
                                    lat={45.266504}
                                    lng={2.603164}
                                    text="Valette"
                                />
                            </GoogleMapReact>
                        </div>
                    </Grid.Column>
                </Grid.Row>

                <Divider/>

                {/*BAKKERS*/}
                <Grid.Row className='grid-row'>
                    <Grid.Column>
                        <div style={{marginBottom: '20px'}}>{localeData.praktisch_bakker}</div>
                    </Grid.Column>
                    <Grid.Column floated='right' width={8}>

                        <div style={{ height: '300px', width: '100%' }}>
                            <GoogleMapReact
                                bootstrapURLKeys={{ key: API_KEY }}
                                defaultCenter={{lat: 45.2480235, lng: 2.693051}}
                                defaultZoom={12}
                                yesIWantToUseGoogleMapApiInternals={true}
                            >
                                <Icon
                                    style={{position: 'absolute', transform: 'translate(-50%, -100%)', cursor: 'pointer'}}
                                    name='map marker alternate' size='big'
                                    lat={45.2480235}
                                    lng={2.693051}
                                    text="Apchon"
                                />
                                {/*<Icon
                                    style={{position: 'absolute', transform: 'translate(-50%, -100%)', cursor: 'pointer'}}
                                    name='map marker alternate' size='big'
                                    lat={45.208276}
                                    lng={2.716221}
                                    text="Cheylade"
                                />*/}
                            </GoogleMapReact>
                        </div>
                    </Grid.Column>
                </Grid.Row>

                <Divider/>

                {/*SUPERMARKT*/}
                <Grid.Row className='grid-row'>
                    <Grid.Column>
                        <div style={{marginBottom: '20px'}}>{localeData.praktisch_supermarkt}</div>
                    </Grid.Column>
                    <Grid.Column floated='right' width={8}>

                        <div style={{ height: '300px', width: '100%' }}>
                            <GoogleMapReact
                                bootstrapURLKeys={{ key: API_KEY }}
                                defaultCenter={{lat: 45.281418, lng: 2.659765}}
                                defaultZoom={14}
                                yesIWantToUseGoogleMapApiInternals={true}
                            >
                                <Icon
                                    style={{position: 'absolute', transform: 'translate(-50%, -100%)', cursor: 'pointer'}}
                                    name='map marker alternate' size='big'
                                    lat={45.281418}
                                    lng={2.659765}
                                    text="Riom-es-Montagnes"
                                />
                            </GoogleMapReact>
                        </div>
                    </Grid.Column>
                </Grid.Row>

                <Divider/>
            </Fragment>
        )
    }
}

Praktisch.propTypes = {
    localeData: PropTypes.object.isRequired,
    onEnlargePhoto: PropTypes.func.isRequired,
}

Praktisch.defaultProps = {}