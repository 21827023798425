import React, {Component, Fragment} from 'react'
import PropTypes from 'prop-types'
import {Grid, Image} from "semantic-ui-react";
import Kamer1 from "./Kamer1";
import Kamer2 from "./Kamer2";
import Kamer3 from "./Kamer3";
import Kamer4 from "./Kamer4";
import Kamer5 from "./Kamer5";

export default class Kamers extends Component {

    constructor(props) {
        super(props)
        this.state = {readMore: false, kamerNummer: 0}
    }

    componentDidMount() {

    }

    handleReadMore = () => {
        this.setState({readMore: true})
    }

    toonKamer = (kamerNummer) => {
        this.setState({readMore: true, kamerNummer})
    }

    render() {
        const {readMore, kamerNummer} = this.state
        const {localeData, onEnlargePhoto, onReserveer} = this.props

        return (
            <Fragment>
                <Grid.Row>
                    <Grid.Column>
                        <div>{localeData.intro_kamers}</div>
                    </Grid.Column>

                    <Grid.Column textAlign='center'>
                        <div className={kamerNummer === 1 ? 'kamer1-color kamer-padding' : ''}>
                            <Image className='clickable-image' bordered rounded size='large' src='./fotos/DSC_1034.jpg' onClick={() => this.toonKamer(1)}/>
                            <h4>La Fontaine</h4>
                        </div>
                    </Grid.Column>
                    <Grid.Column textAlign='center'>
                        <div className={kamerNummer === 2 ? 'kamer2-color kamer-padding' : ''}>
                            <Image className='clickable-image' bordered rounded size='large' src='./fotos/DSC_1040.jpg' onClick={() => this.toonKamer(2)}/>
                            <h4>Le Cheylet</h4>
                        </div>
                    </Grid.Column>
                    <Grid.Column textAlign='center'>
                        <div className={kamerNummer === 3 ? 'kamer3-color kamer-padding' : ''}>
                            <Image className='clickable-image' bordered rounded size='large' src='./fotos/DSC_1051.jpg' onClick={() => this.toonKamer(3)}/>
                            <h4>Le Château</h4>
                        </div>
                    </Grid.Column>
                    <Grid.Column textAlign='center'>
                        <div className={kamerNummer === 4 ? 'kamer4-color kamer-padding' : ''}>
                            <Image className='clickable-image' bordered rounded size='large' src='./fotos/DSC_1058.jpg' onClick={() => this.toonKamer(4)}/>
                            <h4>Le Plateau</h4>
                        </div>
                    </Grid.Column>
                    <Grid.Column textAlign='center'>
                        <div className={kamerNummer === 5 ? 'kamer5-color kamer-padding' : ''}>
                            <Image className='clickable-image' bordered rounded size='large' src='./fotos/DSC_1066.jpg' onClick={() => this.toonKamer(5)}/>
                            <h4>Le Peyre Arse</h4>
                        </div>
                    </Grid.Column>
                </Grid.Row>

                {kamerNummer === 1 &&
                <Kamer1 localeData={localeData} onEnlargePhoto={onEnlargePhoto} reserveer={onReserveer}/>
                }
                {kamerNummer === 2 &&
                <Kamer2 localeData={localeData} onEnlargePhoto={onEnlargePhoto} reserveer={onReserveer}/>
                }
                {kamerNummer === 3 &&
                <Kamer3 localeData={localeData} onEnlargePhoto={onEnlargePhoto} reserveer={onReserveer}/>
                }
                {kamerNummer === 4 &&
                <Kamer4 localeData={localeData} onEnlargePhoto={onEnlargePhoto} reserveer={onReserveer}/>
                }
                {kamerNummer === 5 &&
                <Kamer5 localeData={localeData} onEnlargePhoto={onEnlargePhoto} reserveer={onReserveer}/>
                }

                {/* <Grid.Row className='grid-row'>

                    {readMore &&
                    <Grid.Column color='orange'>
                        {kamerNummer === 2 &&
                        <div className='carousel'>
                            <Image className='clickable-image carousel-content' rounded size='large' src='./fotos/DSC_1040-2.jpg' onClick={onEnlargePhoto}/>
                            <Image className='clickable-image carousel-content' rounded size='large' src='./fotos/DSC_1041-2.jpg' onClick={onEnlargePhoto}/>
                            <Image className='clickable-image carousel-content' rounded size='medium' src='./fotos/DSC_1042-2.jpg' onClick={onEnlargePhoto}/>
                            <Image className='clickable-image carousel-content' rounded size='medium' src='./fotos/DSC_1043-2.jpg' onClick={onEnlargePhoto}/>
                            <Image className='clickable-image carousel-content' rounded size='large' src='./fotos/DSC_1045-2.jpg' onClick={onEnlargePhoto}/>
                            <Image className='clickable-image carousel-content' rounded size='medium' src='./fotos/DSC_1046-2.jpg' onClick={onEnlargePhoto}/>
                            <Image className='clickable-image carousel-content' rounded size='large' src='./fotos/DSC_1047-2.jpg' onClick={onEnlargePhoto}/>
                        </div>
                        }
                        {kamerNummer === 3 &&
                        <div className='carousel'>
                            <Image className='clickable-image carousel-content' rounded size='medium' src='./fotos/DSC_1049-2.jpg' onClick={onEnlargePhoto}/>
                            <Image className='clickable-image carousel-content' rounded size='medium' src='./fotos/DSC_1050-2.jpg' onClick={onEnlargePhoto}/>
                            <Image className='clickable-image carousel-content' rounded size='large' src='./fotos/DSC_1051-2.jpg' onClick={onEnlargePhoto}/>
                            <Image className='clickable-image carousel-content' rounded size='large' src='./fotos/DSC_1052-2.jpg' onClick={onEnlargePhoto}/>
                            <Image className='clickable-image carousel-content' rounded size='medium' src='./fotos/DSC_1054-2.jpg' onClick={onEnlargePhoto}/>
                        </div>
                        }
                        {kamerNummer === 4 &&
                        <div className='carousel'>
                            <Image className='clickable-image carousel-content' rounded size='medium' src='./fotos/DSC_1056-2.jpg' onClick={onEnlargePhoto}/>
                            <Image className='clickable-image carousel-content' rounded size='large' src='./fotos/DSC_1057-2.jpg' onClick={onEnlargePhoto}/>
                            <Image className='clickable-image carousel-content' rounded size='large' src='./fotos/DSC_1058-2.jpg' onClick={onEnlargePhoto}/>
                            <Image className='clickable-image carousel-content' rounded size='medium' src='./fotos/DSC_1059-2.jpg' onClick={onEnlargePhoto}/>
                        </div>
                        }
                        {kamerNummer === 5 &&
                        <div className='carousel'>
                            <Image className='clickable-image carousel-content' rounded size='medium' src='./fotos/DSC_1065-2.jpg' onClick={onEnlargePhoto}/>
                            <Image fluid className='clickable-image carousel-content' rounded size='large' src='./fotos/DSC_1066-2.jpg' onClick={onEnlargePhoto}/>
                            <Image className='clickable-image carousel-content' rounded size='large' src='./fotos/DSC_1067-2.jpg' onClick={onEnlargePhoto}/>
                            <Image className='clickable-image carousel-content' rounded size='large' src='./fotos/DSC_1068-2.jpg' onClick={onEnlargePhoto}/>
                            <Image className='clickable-image carousel-content' rounded size='large' src='./fotos/DSC_1069-2.jpg' onClick={onEnlargePhoto}/>
                            <Image className='clickable-image carousel-content' rounded size='large' src='./fotos/DSC_1070-2.jpg' onClick={onEnlargePhoto}/>
                        </div>
                        }
                    </Grid.Column>
                    }
                </Grid.Row>*/}
            </Fragment>
        )
    }
}

Kamers.propTypes = {
    localeData: PropTypes.object.isRequired,
    onEnlargePhoto: PropTypes.func.isRequired,
    onReserveer: PropTypes.func.isRequired
}

Kamers.defaultProps = {}