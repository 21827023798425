import React from 'react'
import {Header, Label} from 'semantic-ui-react'

export default {

    menu: {activiteiten: 'Activiteiten', praktisch: 'Praktisch', reserveren: 'Reserveren'},

    eetkamer: <div>Onze ruime <h2>Eetkamer</h2> biedt plaats aan 10 tot 12 gasten die er kunnen genieten van ons heerlijk ontbijt of een zelf klaargemaakte middag- of avondmaaltijd. Gratis wifi en televisie zijn aanwezig.</div>,



    reserveren_hauteclair: <div style={{ fontSize: '1.2em' }}>
        <Header as='h3' style={{ fontSize: '2em' }}>
            Hauteclair
        </Header>
        <p>Ady Pinckers</p>
        <p>Résidence Hauteclair</p>
        <p>Le Bourg<br/>
            15400 Apchon<br/>
            vast nr: 04 71 78 20 07<br/>
            gsm: 06 31 30 83 33<br/>
            mail: <a href='mailto:adypinckers@hotmail.com' target='_blank' rel="noopener noreferrer">adypinckers@hotmail.com</a>
        </p>
    </div>,

    reserveren_bijkomend: <div>
        <Header as='h3' style={{ fontSize: '2em' }}>
            Bijkomende info op volgende sites
        </Header>
        <p><a href='https://www.tourisme.fr/518/office-de-tourisme-riom-es-montagnes.htm' target='_blank' rel="noopener noreferrer">OT Pays Gentiane</a></p>
        <p><a href='http://www.cantal-destination.com' target='_blank' rel="noopener noreferrer">Cantaltourisme</a></p>
        <p><a href='http://www.auvergne-tourisme.info/accueil-1-1.html' target='_blank' rel="noopener noreferrer">Auvergnetourisme</a></p>
        <p><a href='https://www.airbnb.fr' target='_blank' rel="noopener noreferrer">Airbnb</a></p>
        <p><a href='https://www.geo.fr' target='_blank' rel="noopener noreferrer">Geo Guide</a></p>
        <p><a href='https://www.petitfute.com' target='_blank' rel="noopener noreferrer">Petit Futé</a></p>
    </div>,

    praktisch_restaurants: <div>
        <Header as='h3' style={{ fontSize: '2em' }}>
            Restaurants in de buurt
        </Header>
        <p style={{ fontSize: '1.2em' }}>
            In het dorp van Apchon op 100m en op 5à10 km met de auto in Riom-ès-Montagnes, Cheylade, Trizac en Valette
        </p>
    </div>,
    praktisch_bakker: <div>
        <Header as='h3' style={{ fontSize: '2em' }}>
            Bakker en kruidenier
        </Header>
        <p style={{ fontSize: '1.2em' }}>
            In het dorp van Apchon.
        </p>
    </div>,
    praktisch_supermarkt: <div>
        <Header as='h3' style={{ fontSize: '2em' }}>
            Grootwarenhuizen en slagers
        </Header>
        <p style={{ fontSize: '1.2em' }}>
            In Riom-es-Montagnes
        </p>
    </div>,

    intro_activiteiten: <div>
        <strong as='h1'>
            Voor elk wat wils
        </strong>
        <Header as='h3' style={{ fontSize: '2em' }}>
            Tal van activiteiten, het hele jaar door
        </Header>
        <p style={{ fontSize: '1.2em' }}>
            Naast talloze prachtige wandelingen is er in de Cantal een ruim aanbod aan zeer uiteenlopende activiteiten. Ben je sportief aangelegd, dan kan je gaan mountainbiken, paardrijden. Voor de echte durvers: het is hier een paradijs om aan parapente te doen.<br/>
            Hou je het liever wat rustiger, dan kan je een ezelstocht boeken of een bootexcursie op één van de meren in de omgeving. Of waarom niet een dagje uit vissen gaan?
        </p>
    </div>,

    activiteiten_vulcania: <div>
        <Header as='h3' style={{ fontSize: '2em' }}>
            Vulcania
        </Header>
        <p style={{ fontSize: '1.2em' }}>
            Een prachtig nieuw centrum over de vulkanen van Auvergne. Een daguitstap is een absolute aanrader! Let wel dat je een volledige dag voorziet want de site is heel groot. Indien je alles wil zien moet je toch 8 uur uittrekken. Vraag een folder in de toerismebureau's.
        </p>
        <p>Meer info op <a href='https://www.vulcania.com' target='_blank' rel="noopener noreferrer">de site van vulcania</a></p>
    </div>,

    activiteiten_maison_aveze: <div>
        <Header as='h3' style={{ fontSize: '2em' }}>
            Maison d'Avèze
        </Header>
        <p style={{ fontSize: '1.2em' }}>
            Maar dan een drankje, is Avèze een artisanale activiteit die sterk verankerd is in de Auvergnaanse traditie. Dat verdient een museum!
        </p>
        <p>Meer info op <a href='https://www.aveze.com/lespace-aveze/' target='_blank' rel="noopener noreferrer">de site van maison d'avèze</a></p>
    </div>,
    activiteiten_maison_du_miel: <div>
        <Header as='h3' style={{ fontSize: '2em' }}>
            De wereld van de bijen
        </Header>
        <p style={{ fontSize: '1.2em' }}>
            Boerderij in het hart van het Vulkanenpark. Verkoop van producten van de Hive, verklarende video van het ambacht van imker. Tentoonstelling: evolutie van bijenkorven, geoogste producten, bijenkorf met bijen in activiteit. Verkoop, degustatie.
        </p>
        <p>Meer info op <a href='http://auvergne-centrefrance.com/saveurs/miel/stsaturnin15/miellerie-le-monde-des-abeilles.html' target='_blank' rel="noopener noreferrer">Miellerie Le Monde des Abeilles</a></p>
    </div>,
    activiteiten_gentiane_express: <div>
        <Header as='h3' style={{ fontSize: '2em' }}>
            Gentiane express
        </Header>
        <p style={{ fontSize: '1.2em' }}>
            Stap aan boord van de Gentiane Express voor een onvergetelijke reis in het hart van 'Le Pays Gentiane'!
        </p>
        <p>Meer info op <a href='https://www.gentiane-express.com' target='_blank' rel="noopener noreferrer">de site van Gentiane Express</a></p>
    </div>,
    activiteiten_fromages: <div>
        <Header as='h3' style={{ fontSize: '2em' }}>
            Route des Fromages
        </Header>
        <p style={{ fontSize: '1.2em' }}>
            Maar liefst 38 etappes op deze kaasroute (boerderijproducenten, kaasmakerijen, rijpers). Ontdek de Bleu d'Auvergne, Cantal , Fourme d'Ambert, Saint-Nectaire, en de Salers kazen.
        </p>
        <p>Meer info op <a href='https://www.fromages-aop-auvergne.com/route-fromages/' target='_blank' rel="noopener noreferrer">de site van 'Route Des Fromages AOP d'Auvergne'</a></p>
    </div>,
    activiteiten_chateau_apchon: <div>
        <Header as='h3' style={{ fontSize: '2em' }}>
            Château d'Apchon
        </Header>
        <p style={{ fontSize: '1.2em' }}>
            De ruïnes van het kasteel van Apchon vormen een uitzonderlijke site in de noordelijke Cantal. Ze bevinden zich op meer dan 1100 m hoogte op een indrukwekkende basaltrots, van waaruit ze de vallei van Petite-Rhue en Pays Gentiane domineren.
        </p>
        <p>Meer info op <a href='https://www.fondation-patrimoine.org/les-projets/chateau-d-apchon' target='_blank' rel="noopener noreferrer">deze site</a></p>
    </div>,
    activiteiten_chaudes_aigues: <div>
        <Header as='h3' style={{ fontSize: '2em' }}>
            Chaudes-Aigues
        </Header>
        <p style={{ fontSize: '1.2em' }}>
            Chaudes-Aigues, kuuroord in Cantal, is een populaire bestemming voor het hele gezin en ligt tussen de Monts du Cantal en het Aubrac-plateau, op een steenworp afstand van Saint-Flour en de Gorges de Truyère. Dit kuuroord met stenen architectuur en leien daken wordt gewaardeerd om zijn menselijke dimensie en zijn uitzonderlijke natuurlijke omgeving.
        </p>
        <p>Meer info op <a href='https://www.pays-saint-flour.fr/incontournables/chaudes-aigues-station-thermale/' target='_blank' rel="noopener noreferrer">deze site</a></p>
    </div>,
    activiteiten_ski: <div>
        <Header as='h3' style={{ fontSize: '2em' }}>
            In de winter Ski en Langlauf
        </Header>
        <p style={{ fontSize: '1.2em' }}>
            Hauteclair situeert zich in het midden van 2 skioorden: Le Lioran naar het zuiden toe en Super-Besse naar het noorden, beiden op 30à40 min
        </p>
        <p>Meer info: <a href='https://www.lelioran.com/index.php?alias=home&chg_saison=hiver' target='_blank' rel="noopener noreferrer">Le Lioran</a> en <a href='https://www.sancy.com/domaine-vtt/station-de-super-besse/' target='_blank' rel="noopener noreferrer">Super Besse</a>
        </p>
    </div>,
    activiteiten_parcours_aventure: <div>
        <Header as='h3' style={{ fontSize: '2em' }}>
            Avonturen parcours in Le Lioran
        </Header>
        <p style={{ fontSize: '1.2em' }}>
            30 minuten van Apchon, in de groene jungle van Lioran, 9 boom tot boom banen die emotie en sensatie brengen, toegankelijk voor iedereen vanaf 3 jaar oud.
        </p>
        <p>Meer info vindt u <a href='http://auvergne.travel/activites-sportives-culturelles-sejour-itinerants/laveissiere/lioran-aventure-parcours-aventure-en-foret/tourisme-ASCAUV0150000285-1.html' target='_blank' rel="noopener noreferrer">hier</a></p>
    </div>,
    activiteiten_velorail: <div>
        <Header as='h3' style={{ fontSize: '2em' }}>
            Vélorail Allanche
        </Header>
        <p style={{ fontSize: '1.2em' }}>
            Een unieke belevenis in een uniek omgeving.
        </p>
        <p>Meer info vindt u <a href='http://www.allanche.fr/velorail.php' target='_blank' rel="noopener noreferrer">hier</a></p>
    </div>,
    activiteiten_pinatelle: <div>
        <Header as='h3' style={{ fontSize: '2em' }}>
            Maison de la Pinatelle
        </Header>
        <p style={{ fontSize: '1.2em' }}>
            Terwijl de roep van het hert weerklinkt met de eerste glimpen van de herfst, opent de Pinhatéla zijn deuren, geeft hij zijn geheimen prijs.
        </p>
        <p>Meer info op <a href='http://www.auvergne-centrefrance.com/geotouring/curieux/chalinargues/chalinargues.html' target='_blank' rel="noopener noreferrer">de site van maison de la Pinatelle</a></p>
    </div>,
    activiteiten_garabit: <div>
        <Header as='h3' style={{ fontSize: '2em' }}>
            Viaduct van Garabit
        </Header>
        <p style={{ fontSize: '1.2em' }}>
            Meer dan 130 jaar na de bouw wekt het viaduct van Garabit de bewondering van iedereen. Aan haar voeten groef La Truyère haar bed in een weelderige vallei met vele facetten.
        </p>
        <p>Meer info op <a href='https://www.pays-saint-flour.fr/incontournables/viaduc-de-garabit/' target='_blank' rel="noopener noreferrer">deze site</a></p>
    </div>,
    activiteiten_millau: <div>
        <Header as='h3' style={{ fontSize: '2em' }}>
            Viaduct van Millau
        </Header>
        <p style={{ fontSize: '1.2em' }}>
            Met een hoogtewereldrecord, het hoogste punt ligt op 343 meter (hoger dan de Eiffeltoren), raakt het 2 460 meter lange viaduct de vallei van de Tarn op slechts 9 punten.
        </p>
        <p>Meer info op <a href='https://www.tourisme-aveyron.com/nl/ontdekken/viaduct-van-millau/ontdek-viaduct-van-millau' target='_blank' rel="noopener noreferrer">deze site</a></p>
    </div>,
    activiteiten_font_sainte: <div>
        <Header as='h3' style={{ fontSize: '2em' }}>
            La Font Sainte
        </Header>
        <p style={{ fontSize: '1.2em' }}>
            Op 1230 meter hoogte, midden in de weiden, staat een kapel naast een heilige bron. Op deze zeer rustige plek kun je het pad nemen dat naar de heuvel leidt, vanwaar je het panorama op het Limon-plateau kunt bewonderen. Toegang vanaf Apchon of Saint-Hippolyte. Sculpturen wandeling met audio begeleiding
        </p>
        <p>Meer info op <a href='http://www.cantal-destination.com/patrimoine-culturel/saint-hippolyte/la-font-sainte/tourisme-PCUAUV0150000402-1.html' target='_blank' rel="noopener noreferrer">deze site</a></p>
    </div>,
    activiteiten_marcenat: <div>
        <Header as='h3' style={{ fontSize: '2em' }}>
            Orthodox klooster in Marcenat
        </Header>
        <p style={{ fontSize: '1.2em' }}>
            Een uniek orthodox klooster op een plaats waar je dat het minst zou verwachten.
        </p>
        <p>Meer info op <a href='http://www.auvergne-centrefrance.com/geotouring/eglises/15/marcenat/monastere-orthodoxe-de-la-traverse.html' target='_blank' rel="noopener noreferrer">deze site</a></p>
    </div>,
    activiteiten_markten: <div>
        <Header as='h3' style={{ fontSize: '2em' }}>
            Feesten en markten
        </Header>
        <p style={{ fontSize: '1.2em' }}>
            Fête de la Gentiane Riom-ès-Montagnes<br/>
            Fête de l’Estive Allanche<br/>
            Fête de la Rosière Riom-ès-Montagnes<br/>
            Fête de la pierre de Menet<br/>
            Festival des Nuits Musicales<br/>
            Fête du Bleu Riom-ès-Montagnes<br/>
            Fête du Cornet de Murat<br/>
            Brocante et vide grenier<br/>
            Foire aux Cloche et Sonnailles Cheylade<br/>
            Foire à la brocante et aux antiquités Allanche<br/>

        </p>
    </div>,

    intro_omgeving: <div>
        <strong as='h1'>
            Cantal - Auvergne
        </strong>
        <Header as='h3' style={{ fontSize: '2em' }}>
            Laat je verleiden door de natuur
        </Header>
        <p style={{ fontSize: '1.2em' }}>
            Vakantie in de grootste vulkaantuin van Europa. Tachtig uitgedoofde vulkanen, een mozaïek aan overweldigende landschappen, immense panorama's, helderblauwe meren, eeuwenoude Romaanse kerkjes en middeleeuwse stadjes.
        </p>
    </div>,

    omgeving_lees_meer: <div>
        <p style={{ fontSize: '1.2em' }}>
            Het zijn de voornaamste kenmerken van een unieke Franse streek: Auvergne. Zin om eens uit te blazen in het hart van het 'Parc Régional des Volcans - Massif du Cantal'? Eén van de prachtigste ongerepte natuurparken. Vergeet de stress en adem de nog zuivere lucht, weg van de stad  in het land van ontelbare bronnen, in het zuiden van de Cantal nabij Riom-es-Montagnes op enkele Km van de hoge vulkaantoppen Puy Mary (1787m) en de Plomb du Cantal (1900m) en 50km van de grotere cultuurstad Aurillac en 80km van Clermont-Ferrand.
        </p>
    </div>,

    intro_hauteclair: <div>
        <strong as='h1'>
            Hauteclair
        </strong>
        <Header as='h3' style={{ fontSize: '2em' }}>
            Kom tot rust in onze prachtige residentie
        </Header>
        <p style={{ fontSize: '1.2em' }}>
            In het hartje van het Parc Régional des Volcans d’Auvergne, 'le grand bol d'air' van Frankrijk vind je onze unieke chambres d'hôtes. Je wordt ondergedompeld tussen diep ingedommelde vulkanen, rustgevende bossen en magische landschappen met grandioze panorama's
        </p>
    </div>,

    hauteclair_lees_meer: <div>
        <p style={{ fontSize: '1.2em' }}>
            Emplacement motos, vélos et matériel de pêche.
        </p>
    </div>,

    intro_kamers: <div>
        <Header as='h3' style={{ fontSize: '2em' }}>
            Vijf kamers voor ieders smaak
        </Header>
        {/*<p style={{ fontSize: '1.2em' }}>
            In het hartje van het Parc Régional des Volcans d’Auvergne, 'le grand bol d'air' van Frankrijk vind je onze unieke chambres d'hôtes. Je wordt ondergedompeld tussen diep ingedommelde vulkanen, rustgevende bossen en magische landschappen met grandioze panorama's
        </p>*/}
    </div>,

    kamer1: <div>
        <strong as='h1'>
            Kamer 1
        </strong>
        <Header as='h3' style={{ fontSize: '2em' }}>
            La Fontaine
        </Header>
        <p style={{ fontSize: '1.2em' }}>
            Deze kamer biedt u een uitzicht op het pelgrimsoord La Font Sainte met een bron met geneeskrachitg water.
            <br/>Een stijlvolle kamer met eigen sanitair
        </p>
    </div>,

    kamer1_info: <div>
        <div style={{ fontSize: '1.2em' }}>
            <p>Prijs voor deze kamer:
                <br />€ 69,- voor 2 personen per nacht.
                <br />€ 69,- voor 1 persoon per nacht.</p>
            <p>Ontbijt inbegrepen.</p>
            <p>Taxe de séjour niet inbegrepen: € 0,75 + s.t. per persoon.</p>
            <Label tag color='teal'>7 nachten reserveren = 1 bijkomende nacht gratis</Label>
        </div>
    </div>,

    kamer2: <div>
        <strong as='h1'>
            Kamer 2
        </strong>
        <Header as='h3' style={{ fontSize: '2em' }}>
            Le Cheylet
        </Header>
        <p style={{ fontSize: '1.2em' }}>
            Deze kamer biedt u een uitzicht op de tuin en in de hoogt op le cheylet, de Maagd Marie die ons beschermt tegen het onweer.
            <br/>Een zeer ruime en stijlvolle familiekamer met aparte badkamer
        </p>
    </div>,

    kamer2_info: <div>
        <div style={{ fontSize: '1.2em' }}>
            <p>Prijs voor deze kamer:
                <br/>€ 75,- voor 2 personen per nacht.
                <br />gratis 1 extra kinderbed (tot 2 jaar).
                <br />€ 25,- voor 1 extra kinderbed (vanaf 2 jaar).
            </p>
            <p>Ontbijt inbegrepen.</p>
            <p>Taxe de séjour niet inbegrepen: € 0,75 + s.t. per persoon.</p>
            <Label tag color='teal'>Vanaf 4 nachten reserveren: € 69,- per nacht!</Label>
        </div>
    </div>,


    kamer3: <div>
        <strong as='h1'>
            Kamer 3
        </strong>
        <Header as='h3' style={{ fontSize: '2em' }}>
            Le Château
        </Header>
        <p style={{ fontSize: '1.2em' }}>
            Deze kamer biedt u een uitzicht op de ruïnes van het chateau d'Apchon.
            <br/>Een ruime en stijlvolle kamer met eigen sanitair
        </p>
    </div>,

    kamer3_info: <div>
        <div style={{ fontSize: '1.2em' }}>
            <p>Prijs voor deze kamer:
                <br/>€ 69,- voor 2 personen per nacht.
                <br/>€ 69,- voor 1 persoon per nacht.
                <br />€ 25,- voor 1 extra kinderbed (vanaf 2 jaar).
            </p>
            <p>Ontbijt inbegrepen.</p>
            <p>Taxe de séjour niet inbegrepen: € 0,75 s.t. per persoon.</p>
            <Label tag color='teal'>7 nachten reserveren = 1 bijkomende nacht gratis</Label>
        </div>
    </div>,

    kamer4: <div>
        <strong as='h1'>
            Kamer 4
        </strong>
        <Header as='h3' style={{ fontSize: '2em' }}>
            Le Plateau
        </Header>
        <p style={{ fontSize: '1.2em' }}>
            Deze kamer biedt u een uitzicht op de hoogvlakte van Le Limon en op de vallei van Cheylade.
            <br/>Stijlvolle kamer met 2 eenpersoonsbedden en eigen sanitair
        </p>
    </div>,

    kamer4_info: <div>
        <div style={{ fontSize: '1.2em' }}>
            <p>Prijs voor deze kamer:
                <br/>€ 69,- voor 2 personen per nacht.
                <br/>€ 69,- voor 1 persoon per nacht.
            </p>
            <p>Ontbijt inbegrepen.</p>
            <p>Taxe de séjour niet inbegrepen: € 0,75 + s.t. per persoon.</p>
            <Label tag color='teal'>7 nachten reserveren = 1 bijkomende nacht gratis</Label>
        </div>
    </div>,

    kamer5: <div>
        <strong as='h1'>
            Kamer 5
        </strong>
        <Header as='h3' style={{ fontSize: '2em' }}>
            Le Peyre Arse
        </Header>
        <p style={{ fontSize: '1.2em' }}>
            Deze kamer biedt u een uitzicht op de tuinen en op de Peyre Arse.
            <br/>Een zeer ruime en stijlvolle familiekamer met een tweepersoonsbed en een eenpersoonsbed en apart sanitair
        </p>
    </div>,

    kamer5_info: <div>
        <div style={{ fontSize: '1.2em' }}>
            <p>Prijs voor deze kamer:
                <br/>€ 75,- voor 2 personen per nacht.
                <br />€ 25,- voor 1 extra kinderbed (vanaf 2 jaar).
            </p>
            <p>Ontbijt inbegrepen.</p>
            <p>Taxe de séjour niet inbegrepen: € 0,75 + s.t. per persoon.</p>
            <Label tag color='teal'>Vanaf 4 nachten reserveren: € 69,- per nacht!</Label>
        </div>
    </div>,

    keuken: <div>Een uitstekend ingerichte <h2>Keuken</h2> stellen wij ter beschikking voor gasten die 's middags of 's avonds hun picknick of maatlijd willen bereiden.</div>,

    lees_meer: 'Lees meer',

    living: <div>In onze ruime <h2>Living</h2> kan je gezellig zetelen en tv kijken maar ook genieten van een heerlijk en uitgebreid ontbijt.</div>,

    reserveer: 'Reserveer',

    sluit: 'Sluit',

    terras: <div>Op ons groot <h2>Terras</h2> kan je, als het weer het toelaat, genieten van een fenomenaal uitzicht op de vallée de Cheylade. Het terras is op het zuiden gericht zodat je de hele dag door kan genieten van de zon.</div>,

    welkom_in_hauteclair: 'Welkom in Hauteclair'
}
