import React, {Component, Fragment} from 'react'
import PropTypes from 'prop-types'
import {Button, Divider, Grid, Image} from "semantic-ui-react";

export default class Activiteiten extends Component {

    constructor(props) {
        super(props)
        this.state = {readMore: false}
    }

    componentDidMount() {

    }

    handleReadMore = () => {
        this.setState({readMore: true})
    }

    render() {
        const {readMore} = this.state
        const {localeData, onEnlargePhoto} = this.props

        return (
            <Fragment>
                <Grid.Row color='blue'>
                    <Grid.Column>
                        <div style={{marginBottom: '20px'}}>{localeData.intro_activiteiten}</div>
                    </Grid.Column>
                    <Grid.Column floated='right' width={8}>
                        <Image className='clickable-image' bordered rounded size='big' src='./fotos/DSC_6932.jpg' onClick={onEnlargePhoto}/>
                    </Grid.Column>
                </Grid.Row>

                <Divider/>

                {/*VULCANIA*/}
                <Grid.Row className='grid-row'>
                    <Grid.Column floated='right' width={8}>
                        <Image className='clickable-image' bordered rounded size='big' src='./fotos/vulcania.jpg' onClick={onEnlargePhoto}/>
                    </Grid.Column>
                    <Grid.Column>
                        <div style={{marginBottom: '20px'}}>{localeData.activiteiten_vulcania}</div>
                    </Grid.Column>
                </Grid.Row>

                <Divider/>

                {/*MAISON D'AVEZE*/}
                <Grid.Row className='grid-row'>
                    <Grid.Column>
                        <div style={{marginBottom: '20px'}}>{localeData.activiteiten_maison_aveze}</div>
                    </Grid.Column>
                    <Grid.Column floated='right' width={8}>
                        <Image className='clickable-image' bordered rounded size='big' src='./fotos/aveze.jpg' onClick={onEnlargePhoto}/>
                    </Grid.Column>
                </Grid.Row>

                <Divider/>

                {/*MAISON DU MIEL*/}
                <Grid.Row className='grid-row'>
                    <Grid.Column floated='right' width={8}>
                        <Image className='clickable-image' bordered rounded size='big' src='./fotos/miel.jpg' onClick={onEnlargePhoto}/>
                    </Grid.Column>
                    <Grid.Column>
                        <div style={{marginBottom: '20px'}}>{localeData.activiteiten_maison_du_miel}</div>
                    </Grid.Column>
                </Grid.Row>

                <Divider/>

                {/*GENTIANE EXPRESS*/}
                <Grid.Row className='grid-row'>
                    <Grid.Column>
                        <div style={{marginBottom: '20px'}}>{localeData.activiteiten_gentiane_express}</div>
                    </Grid.Column>
                    <Grid.Column floated='right' width={8}>
                        <Image className='clickable-image' bordered rounded size='big' src='./fotos/gentiane_express.jpg' onClick={onEnlargePhoto}/>
                    </Grid.Column>
                </Grid.Row>

                <Divider/>

                {/*FROMAGES*/}
                <Grid.Row className='grid-row'>
                    <Grid.Column floated='right' width={8}>
                        <Image className='clickable-image' bordered rounded size='big' src='./fotos/fromages.jpg' onClick={onEnlargePhoto}/>
                    </Grid.Column>
                    <Grid.Column>
                        <div style={{marginBottom: '20px'}}>{localeData.activiteiten_fromages}</div>
                    </Grid.Column>
                </Grid.Row>

                <Divider/>

                {/*CHATEAU D'APCHON*/}
                <Grid.Row className='grid-row'>
                    <Grid.Column>
                        <div style={{marginBottom: '20px'}}>{localeData.activiteiten_chateau_apchon}</div>
                    </Grid.Column>
                    <Grid.Column floated='right' width={8}>
                        <Image className='clickable-image' bordered rounded size='big' src='./fotos/chateau_apchon.jpg' onClick={onEnlargePhoto}/>
                    </Grid.Column>
                </Grid.Row>

                <Divider/>

                {/*CHAUDES-AIGUES*/}
                <Grid.Row className='grid-row'>
                    <Grid.Column floated='right' width={8}>
                        <Image className='clickable-image' bordered rounded size='big' src='./fotos/chaudes_aigues.jpg' onClick={onEnlargePhoto}/>
                    </Grid.Column>
                    <Grid.Column>
                        <div style={{marginBottom: '20px'}}>{localeData.activiteiten_chaudes_aigues}</div>
                    </Grid.Column>
                </Grid.Row>

                <Divider/>

                {/*PARCOURS AVENTURE*/}
                <Grid.Row className='grid-row'>
                    <Grid.Column>
                        <div style={{marginBottom: '20px'}}>{localeData.activiteiten_parcours_aventure}</div>
                    </Grid.Column>
                    <Grid.Column floated='right' width={8}>
                        <Image className='clickable-image' bordered rounded size='big' src='./fotos/parcours_aventure.jpg' onClick={onEnlargePhoto}/>
                    </Grid.Column>
                </Grid.Row>

                <Divider/>

                {/*VELORAIL*/}
                <Grid.Row className='grid-row'>
                    <Grid.Column floated='right' width={8}>
                        <Image className='clickable-image' bordered rounded size='big' src='./fotos/velorail.jpg' onClick={onEnlargePhoto}/>
                    </Grid.Column>
                    <Grid.Column>
                        <div style={{marginBottom: '20px'}}>{localeData.activiteiten_velorail}</div>
                    </Grid.Column>
                </Grid.Row>

                <Divider/>

                {/*PINATELLE*/}
                <Grid.Row className='grid-row'>
                    <Grid.Column>
                        <div style={{marginBottom: '20px'}}>{localeData.activiteiten_pinatelle}</div>
                    </Grid.Column>
                    <Grid.Column floated='right' width={8}>
                        <Image className='clickable-image' bordered rounded size='big' src='./fotos/pinatelle.jpg' onClick={onEnlargePhoto}/>
                    </Grid.Column>
                </Grid.Row>

                <Divider/>

                {/*GARABIT*/}
                <Grid.Row className='grid-row'>
                    <Grid.Column floated='right' width={8}>
                        <Image className='clickable-image' bordered rounded size='big' src='./fotos/garabit.jpg' onClick={onEnlargePhoto}/>
                    </Grid.Column>
                    <Grid.Column>
                        <div style={{marginBottom: '20px'}}>{localeData.activiteiten_garabit}</div>
                    </Grid.Column>
                </Grid.Row>

                <Divider/>

                {/*MILLAU*/}
                <Grid.Row className='grid-row'>
                    <Grid.Column>
                        <div style={{marginBottom: '20px'}}>{localeData.activiteiten_millau}</div>
                    </Grid.Column>
                    <Grid.Column floated='right' width={8}>
                        <Image className='clickable-image' bordered rounded size='big' src='./fotos/millau.jpg' onClick={onEnlargePhoto}/>
                    </Grid.Column>
                </Grid.Row>

                <Divider/>

                {/*FONT SAINTE*/}
                <Grid.Row className='grid-row'>
                    <Grid.Column floated='right' width={8}>
                        <Image className='clickable-image' bordered rounded size='big' src='./fotos/font_sainte.jpg' onClick={onEnlargePhoto}/>
                    </Grid.Column>
                    <Grid.Column>
                        <div style={{marginBottom: '20px'}}>{localeData.activiteiten_font_sainte}</div>
                    </Grid.Column>
                </Grid.Row>

                <Divider/>

                {/*MARCENAT*/}
                <Grid.Row className='grid-row'>
                    <Grid.Column>
                        <div style={{marginBottom: '20px'}}>{localeData.activiteiten_marcenat}</div>
                    </Grid.Column>
                    <Grid.Column floated='right' width={8}>
                        <Image className='clickable-image' bordered rounded size='big' src='./fotos/marcenat.jpg' onClick={onEnlargePhoto}/>
                    </Grid.Column>
                </Grid.Row>

                <Divider/>

                {/*SKI*/}
                <Grid.Row className='grid-row'>
                    <Grid.Column floated='right' width={8}>
                        <Image className='clickable-image' bordered rounded size='big' src='./fotos/le_lioran_ski.jpg' onClick={onEnlargePhoto}/>
                    </Grid.Column>
                    <Grid.Column>
                        <div style={{marginBottom: '20px'}}>{localeData.activiteiten_ski}</div>
                    </Grid.Column>
                </Grid.Row>

                <Divider/>

                {/*MARKTEN*/}
                <Grid.Row className='grid-row'>
                    <Grid.Column>
                        <div style={{marginBottom: '20px'}}>{localeData.activiteiten_markten}</div>
                    </Grid.Column>
                    <Grid.Column floated='right' width={8}>
                        <Image className='clickable-image' bordered rounded size='big' src='./fotos/bleu_dauvergne.jpg' onClick={onEnlargePhoto}/>
                    </Grid.Column>
                </Grid.Row>

                <Divider/>

            </Fragment>
        )
    }
}

Activiteiten.propTypes = {
    localeData: PropTypes.object.isRequired,
    onEnlargePhoto: PropTypes.func.isRequired,
}

Activiteiten.defaultProps = {}