import React, {Component, Fragment} from 'react'
import PropTypes from 'prop-types'
import {Button, Grid, Image, Divider} from "semantic-ui-react";

export default class Kamer4 extends Component {


    render() {
        const {localeData, onEnlargePhoto, reserveer} = this.props

        return (
            <Fragment>
                <Grid.Row className='kamer4-color'>
                    <Grid.Column >
                        <div>{localeData.kamer4}</div>
                    </Grid.Column>

                    <Grid.Column width={6}>

                        <Image className='clickable-image' bordered rounded size='large' src='./fotos/DSC_1058.jpg' onClick={onEnlargePhoto}/>
                    </Grid.Column>

                </Grid.Row>

                <Grid.Row>

                    <Grid.Column width={4}>
                        <Image className='clickable-image' bordered rounded size='medium' src='./fotos/DSC_1056.jpg' onClick={onEnlargePhoto}/>
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <Image className='clickable-image' bordered rounded size='medium' src='./fotos/DSC_1059.jpg' onClick={onEnlargePhoto}/>
                    </Grid.Column>
                    <Grid.Column>
                        {localeData.kamer4_info}
                    </Grid.Column>

                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Button size='tiny' onClick={reserveer}>{localeData.reserveer} La Plateau</Button>
                    </Grid.Column>
                </Grid.Row>

            </Fragment>
        )
    }
}

Kamer4.propTypes = {
    localeData: PropTypes.object.isRequired,
    onEnlargePhoto: PropTypes.func.isRequired,
    reserveer: PropTypes.func.isRequired,
}

Kamer4.defaultProps = {}