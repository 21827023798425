import React, {Component, Fragment} from 'react'
import PropTypes from 'prop-types'
import {Button, Grid, Image, Divider} from "semantic-ui-react";

export default class Kamer5 extends Component {


    render() {
        const {localeData, onEnlargePhoto, reserveer} = this.props

        return (
            <Fragment>
                <Grid.Row className='kamer5-color'>
                    <Grid.Column>
                        <div>{localeData.kamer5}</div>
                    </Grid.Column>

                    <Grid.Column width={6}>

                        <Image className='clickable-image' bordered rounded size='large' src='./fotos/DSC_1066.jpg' onClick={onEnlargePhoto}/>
                    </Grid.Column>

                </Grid.Row>

                <Grid.Row>
                    <Grid.Column width={6}>
                        <Image className='clickable-image' bordered rounded size='large' src='./fotos/DSC_1067.jpg' onClick={onEnlargePhoto}/>
                    </Grid.Column>

                    <Grid.Column>
                        {localeData.kamer5_info}
                    </Grid.Column>

                </Grid.Row>

                <Grid.Row>
                    <Grid.Column>
                        <Image className='clickable-image' bordered rounded size='large' src='./fotos/DSC_1068.jpg' onClick={onEnlargePhoto}/>
                    </Grid.Column>
                    <Grid.Column>
                        <Image className='clickable-image' bordered rounded size='large' src='./fotos/DSC_1069.jpg' onClick={onEnlargePhoto}/>
                    </Grid.Column>
                    <Grid.Column>
                        <Image className='clickable-image' bordered rounded size='large' src='./fotos/DSC_1070.jpg' onClick={onEnlargePhoto}/>
                    </Grid.Column>

                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Button size='tiny' onClick={reserveer}>{localeData.reserveer} Le Peyre Arse</Button>
                    </Grid.Column>
                </Grid.Row>

            </Fragment>
        )
    }
}

Kamer5.propTypes = {
    localeData: PropTypes.object.isRequired,
    onEnlargePhoto: PropTypes.func.isRequired,
    reserveer: PropTypes.func.isRequired,
}

Kamer5.defaultProps = {}